.widget{
    background-color: #fff;
    .icon{
        color: blue;
        font-size: 2rem;
    }
    ul{
        padding-left:16px;
        list-style-type: disc;
        color: white;
        margin: 0;
        >li{
            font-size: 0.8rem;
            font-weight: 500;
            &:first-child{
                color:rgb(0, 255, 0);
            }
            &:nth-child(2){
                color: rgb(157, 157, 0);
            }
            &:last-child{
                color: rgb(119, 0, 0);
            }
        }
    }
}
.orderStatisticsChart{
    position: relative;
    width: 100%;
    height: 100%;
}
.product{
    img{
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
    }
}
.ubiquitousPd{
    overflow-y: scroll;
}
.overview{
    text-align: center;
    h3{
        color:var(--sub-color-600);
        padding-top:4px !important;
    }
}