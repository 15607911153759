@import "~@flaticon/flaticon-uicons/css/all/all";

*{
  --primary-color-800:rgba(70, 70, 124, 0.855);
  --primary-color-600:rgba(68, 68, 129, 0.436);
  --primary-color-400:#9DB2BF;
  --primary-color-200:#DDE6ED;
  --primary-color-100:#edf3f8;
  --text-dark:#2C3333;
  --text-dark-200:#49545a;
  --text-dark-400:#373c3f;
  --text-light:white;
  --sub-color-800:#223c43;
  --sub-color-700:#304a51;
  --sub-color-600:#395B64;
  --sub-color-400:#41707c;
  --light-color-1:#512eff;
  --danger-color:#ff0000bf;
  --base-radius:0.5rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edf1f4 !important;
}
a{
  color:inherit !important;
  text-decoration: inherit !important;
  font:inherit !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
label{
  width: 100%;
  text-align: left;
  font-size: small;
}
.btn .icon{
  margin-right: 8px;
}
.list-item{

}
[data-justify-col-end]{
  margin-left:attr(data-justify-col-end);
}
.inherit-link{
    text-decoration: inherit;
    font-size: inherit;
    color: inherit;
}
.inherit-link:hover{
    color: var(--primary-color-800);
    text-decoration:underline;
}
.border-primary-600{
  border-color: var(--primary-color-600);
}
.border-primary-800{
  border-color: var(--primary-color-800);
}
.card{
  border: none !important;
  box-shadow: #7c73c027 0px 0px 16px;
  border-radius: 0.8rem;
}
.description{
  color: var( --primary-color-600)
}
@media (max-width:512px) {
  .col-xs-0{
    display: none;
  }
  .h-xs-full{
    height: 100%;
  }
}