.rateStars{
    position: relative;
    background-color: white;
    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
        .star{
            color: black;
            width: fit-content;
        }
    }
    .layer{
        position: absolute;
        top: 0;
        left:0;
        background-color: rgb(249, 249, 48);
        mix-blend-mode: screen;
        width: 100%;
        height: 100%;
    }
}