.inputRadio {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    position: relative;
    input {
        position: absolute;
        opacity: 0;
        left: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }
    input:checked {
        ~ .icon{
            transform:translate(50%,-50%) scale(1);
        }
        ~ .label{
            border-color:rgb(68, 68, 129);
        }
    }
    .label{
        padding:4px 8px;
        font-size: 0.78rem;
        font-weight:500;
        border-radius: 0.3rem;
        border:1px solid var(--primary-color-600);
    }
    .icon{
        position: absolute;
        top:0;
        right: 0;
        font-size: 0.9rem;
        color:rgb(68, 68, 129);
        transform: translate(50%,-50%) scale(0);
        transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        i{
            font-size: inherit;
            color: inherit;
        }
    }
    &.disabled{
        filter: grayscale(0.1);
        .label{
            background-color: rgba(0, 0, 0, 0.1);
            opacity: 0.7;
        }
    }
}
