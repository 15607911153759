.formContainer{
    max-width: 800px;
    .header{
        text-align: center;
    }
    .formBg{
        padding: 0;
        margin: 0;
        img{
            border-bottom-left-radius:  var(--base-radius);
            border-top-left-radius:   var(--base-radius);
            height: 100%;
            width: 105%;
            object-fit: cover;
        }
    }
    
}