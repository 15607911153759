$thick:5px;
$color:#392fc6;
.loader{
    position: absolute;
    top: 0px;
    z-index: 99999;
    left: 0px;
    width: 100%;
    .bar{
        position: relative;
        height: $thick;
        top:0px;
        left:0px;
        width: 100%;
        overflow: hidden;
        &::after{
            position: absolute;
            width: 40%;
            height: $thick;
            top: 0px;
            left: 0px;
            content: " ";
            background-color: $color;
            animation: animloader 2s linear infinite;
        }
    }
    @keyframes animloader {
        0% {
          left: 0;
          transform: translateX(-100%);
        }
        100% {
          left: 100%;
          transform: translateX(0%);
        }
      }
    
}
.content{
    text-align: center;
    position: absolute;
    top: 50%;
}