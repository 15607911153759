.orderItem {
    padding: 12px 8px;

    .image {
        img {
            width: 100%;
        }
    }

    .productDetail {
        align-self: flex-start;
    }

    .productName {
        font-weight: 500;
        font-size: 1.2rem;
        color: var(--primary-color-800);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 400px;
        color: red;

    }

    .price {
        padding: 8px 0px;
        color: var(--primary-color-600);
        font-weight: 600;
    }

    .optionBtn {
        background-color: var(--primary-color-200);
        color: var(--sub-color-700);
        margin-top: 8px;
        display: flex;
        align-items: center;
        border: none;
        border-radius: 0.3rem;
        font-size: 0.8rem;
        padding: 2px 8px;

        &:hover {
            background-color: var(--primary-color-400);
        }

        span {
            &::after {
                content: " ";
                padding-right: 10px;
            }
        }
    }

    .quantityChange {
        margin-top: 10px;
    }

    .total {
        padding-top: 8px;
        display: flex;
        justify-content: end;
        flex-direction: column;
        align-items: end;

        .label {
            font-size: 0.8rem;
            width: fit-content;
        }

        .value {
            font-weight: 600;
            width: fit-content;
        }
    }
}