@import '../../../global.module.scss';

.container {
    --radius: 0.75rem;
    --border-color: rgba(0, 0, 0, 0.118);
    border-radius: var(--radius);
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border: 1px solid var(--border-color);
    height: 32px;
    color: rgba(0, 0, 0, 0.698);

    input {
        border: none;
        padding: 8px 0px 8px 12px;
        color: inherit;
        font-weight: 500;
        flex: 1;

        &:focus {
            border: none;
            outline: none;
        }
    }

    &:focus-within {
        border: 1px solid rgba(0, 0, 0, 0.234);
    }

    .icon {
        height: 32px;
        display: flex;
        align-items: center;
        line-height: 0px;
        height: 100%;
        padding: 0px 8px;
        border-radius: 0px var(--radius) 0px 0px;

        &:hover {
            background-color: var(--primary-color-200);
        }
    }
}

.searchResult {
    background-color: white;
    line-height: normal;
    border-radius: 0.65rem;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

    .result {

        .productItem {
            &:hover {
                background-color: rgba(0, 0, 0, 0.077);
            }

            display: flex;
            flex-direction: row;

            .picture {
                width: 25%;

                img {
                    width: 100%;
                    aspect-ratio: 1/1;
                    object-fit: contain;

                }
            }

            .spec {
                width: 75%;
                padding-left: 8px;

                div {
                    padding-bottom: 4px;
                }

                .name {
                    font-weight: 500;

                }

                .price {
                    color: var(--primary-color-800)
                }
            }
        }
    }
}