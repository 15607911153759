@import '../../global.module.scss';
$radius: 0.2rem;

.inputNumber {
    width: 100%;


    input[type="number"] {
        @include mobile {
            font-size: 0.7rem;
        }

        border: none;
        border: 1px solid var(--primary-color-400);
        text-align: center;
        max-width: 60px;
        padding: 4px 0px;

        &:focus {
            border: 1px solid var(--primary-color-400);
            outline: none;
            border: none;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            display: none;
            -webkit-appearance: none;
            margin: 0;
        }

    }

    button {
        border: none;
        background-color: var(--primary-color-800);
        padding: 0px 12px;
        color: white;

        &:hover {
            background-color: var(--primary-color-600);
        }

        &:first-child {
            border-top-left-radius: $radius;
            border-bottom-left-radius: $radius;
        }

        &:last-child {
            border-top-right-radius: $radius;
            border-bottom-right-radius: $radius;
        }
    }

}