.productCard {
    width: 100%;
    background-color: white;
    border-radius: var(--base-radius);
    padding: 4px;

    &:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
    }

    .productName {
        font-size: 0.8rem;
        font-weight: 500;
        padding: 6px 0px 3px 0px;
    }

    .manufacturer {
        padding: 0 0 6px 0;
        font-weight: 500;
        color: var(--primary-color-800)
    }

    .productDetail {
        padding: 8px;
    }

    .productPrice {
        .current {
            color: var(--sub-color-600);
            font-weight: 500;

            &::after {
                content: "đ";
                margin-left: 3px;
                font-size: 0.7rem;
            }
        }

        .before {
            font-size: 0.9rem;
            padding-left: 5px;
            text-decoration: line-through;
            color: var(--primary-color-600);

            &::after {
                content: "đ";
                font-size: 0.7rem;
            }
        }

        .percent {
            font-size: 0.6rem;
            background-color: var(--primary-color-600);
            padding: 1px 4px;
            color: white;
            margin-left: 4px;

            &::after {
                content: "%";
            }
        }
    }

    .rate {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-top: 4px;

        .buy {
            margin-left: 5px;
            font-size: 0.8rem;
        }
    }
}