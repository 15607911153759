@import '../../../global.module.scss';

.carousel {
    width: 100%;
    aspect-ratio: 5/1;

    .content {
        background-color: antiquewhite;


        img {
            object-fit: cover;
            height: fit-content;
            width: 100%;
        }
    }

}